<template>
  <div>
    <vx-card actionable class="cardx" title="">
      <vs-row type="flex" style="margin-top: 2%">
        <!-- <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-button
            class="rounded-r-none p-3 md:px-8 md:py-3"
            @click="cmTeam('CM')"
            :color="colorx"
            :style="cmTeamSelectButtonColor"
            size="small"
            type="border"
            >CM</vs-button
          >
          <vs-button
            class="rounded-l-none p-3 md:px-8 md:py-3"
            @click="exedTeam('ExEd')"
            :color="colorx"
            :style="exedTeamSelectButtonColor"
            size="small"
            type="border"
            >ExEd</vs-button
          >
        </vs-col> -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1.5">
          <treeselect openDirection='bottom' :multiple="true"  style="width:100%;"
            :limit="3"  :value-consists-of="'LEAF_PRIORITY'"   :options="ne_courses" placeholder="NE Course" v-model="selected_course" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.25">
            <treeselect openDirection='bottom' :multiple="true"  style="width:100%;"
            :limit="3"  :value-consists-of="'LEAF_PRIORITY'"   :options="ne1_sources" placeholder="NE Bucket 1 Source" v-model="ne_bucket1_source" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.25">
          <treeselect openDirection='bottom' :multiple="true"  style="width:100%;"
          :limit="3"  :value-consists-of="'LEAF_PRIORITY'"   :options="ne2_sources" placeholder="NE Bucket 2 Source" v-model="ne_bucket2_source" />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5">
          <date-range-picker ref="picker" :opens="'left'" :locale-data="{
            firstDay: 1,
            format: 'DD-MMM-YYYY',
            customRangeLabel: 'Custom Range',
            }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
            :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="dateRange"
            style="width: 240px; margin-right: 2%"></date-range-picker>
        </vs-col>
        <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1.5"
          >
          <vs-checkbox v-model="visit_scheduled" color="#0044BA"
            class="checkboxInput"> Visit Scheduled
          </vs-checkbox>
          </vs-col> -->
        <vs-col
            v-if="selectedTeam === 'CM'"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >
          <vs-button
            color="dark"
            type="filled"
            @click="getGmReportingSpocs(selectedTeam)"
          >GO</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="1"
          >          <vs-button radius class="gap" color="dark" type="gradient" icon="refresh"
            @click="refreshFilterData"></vs-button>
          </vs-col>
         <vs-col
          v-if="selectedTeam === 'ExEd'"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <vs-button
            color="dark"
            type="filled"
            @click="getGmReportingSpocs(selectedTeam);"
            >GO</vs-button>
        </vs-col>
      </vs-row>
      <vx-card actionable class="cardx" style="margin-top: 3%">
        <vs-row type="flex" style="margin-top: 2%">
          <vs-col
            vs-order="1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
            style="font-size: 19px"
          >
            <b>{{ selectedTeam }} - Net Enquiries Transfer</b>
          </vs-col> </vs-row
        ><br />
        <vs-table :data="sortSpoc">
          <template slot="thead">
            <vs-th>Spoc&nbsp;Name</vs-th>
            <!-- <vs-th>Net Enquiries</vs-th> -->
            <vs-th>NE&nbsp;Bucket&nbsp;1</vs-th>
            <vs-th><span class="ml-6">Action</span></vs-th>
            <vs-th>NE&nbsp;Bucket&nbsp;2 </vs-th>
            <vs-th><span class="ml-6">Action</span></vs-th>
            <vs-th>MHP&nbsp;Called</vs-th>
            <vs-th><span class="ml-10">Action</span></vs-th>
            <vs-th>MHP&nbsp;Not&nbsp;Called</vs-th>
            <vs-th><span class="ml-10">Action</span></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].first_name">
                {{ data[indextr].full_name }}
                {{ data[indextr].user_tag ? "-" : "" }}
                {{
                  data[indextr].user_tag ? data[indextr].user_tag : ""
                }}</vs-td
              >
              <!-- <vs-td :data="data[indextr].first_name">
                {{ data[indextr].net_enquiries }}
              </vs-td> -->
              <vs-td :data="data[indextr].first_name">
                {{ data[indextr].ne_bucket_1 }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                  <vs-button size="small" icon="edit" @click="editNe_1(data[indextr])" color="success"
                    ><span class="editTxt">NE&nbsp;1</span></vs-button
                  >
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                {{ data[indextr].ne_bucket_2 }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                  <vs-button size="small" small icon="edit" @click="editNe_2(data[indextr])" color="success"
                    ><span class="editTxt">NE&nbsp;2</span></vs-button
                  >
              </vs-td>
              <vs-td style="text-align: center;" :data="data[indextr].first_name">
                {{ data[indextr].mhp_called }}
              </vs-td>
              <vs-td>
              <vs-td :data="data[indextr].first_name">
              <vs-button size="small" icon="edit" @click="mhpCalledPopUp(data[indextr])" color="warning"
                ><span class="editTxt">Called</span></vs-button
              >
              </vs-td>
              </vs-td>
              <vs-td style="text-align: center;" :data="data[indextr].first_name">
                {{ data[indextr].mhp_not_called }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
              <vs-button size="small" icon="edit" @click="mhpNotCalledPopUp(data[indextr])" color="warning"
                ><span class="editTxt">Not&nbsp;Called</span></vs-button
              >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </vx-card>

    <vs-popup
      class="assignspocpopupmultiplewidthnet"
      :active.sync="netEnquiriesPopup"
      :title="editspoctitle"
    >
      <div>
        <vs-row>
          <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
            <b>Course :</b>
          </vs-col>
          <vs-col vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
            <treeselect openDirection='bottom' :multiple="true"  style="width:420px;position:fixed; z-index:1100"
            :value-consists-of="'LEAF_PRIORITY'" :limit="3"   :options="ne_courses" placeholder="Course" v-model="selected_ne_course" />
          </vs-col>
          </vs-row>
        <br />
        <vs-row class="mt-2">
          <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
            <b>Source :</b>
          </vs-col>
          <vs-col v-if="ne_bucket == 1" vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
            <treeselect openDirection='bottom' :multiple="true"  style="width:420px;position:fixed; z-index:1000"
            :value-consists-of="'LEAF_PRIORITY'" :limit="3"   :options="ne1_sources" placeholder="Sources" v-model="selected_ne1_source" />
          </vs-col>
          <vs-col v-else-if="ne_bucket == 2" vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
            <treeselect openDirection='bottom' :multiple="true"  style="width:420px;position:fixed; z-index:1000"
            :value-consists-of="'LEAF_PRIORITY'" :limit="3"  :options="ne2_sources" placeholder="Sources" v-model="selected_ne2_source" />
          </vs-col>
          </vs-row
        >

        <br />
        <vs-row>
          <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-input
              class="inputx"
              placeholder="Count"
              v-model="editNetEnquiriecount"
            />
          </vs-col>
          <vs-col vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
            <v-select
              style="width: 420px; position: fixed; z-index: 900"
              v-model="selectedColoumnSpoc"
              :options="iiml_spocs"
              label="full_name_team"
            ></v-select>
          </vs-col>
          <vs-col vs-w="2" vs-justify="center" vs-align="center">
            <vs-button
              color="success"
              @click="transferNetEnquiries()"
              type="filled"
              >Assgin</vs-button
            >
          </vs-col></vs-row
        >

        <br />
      </div>
    </vs-popup>
    <vs-popup
      class="assignspocpopupmultiplewidthnet"
      :active.sync="mhpnetEnquiriesPopup"
      :title="mhpeditspoctitle"
    >
      <div>
        <vs-row>
          <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-input
              class="inputx"
              placeholder="Count"
              v-model="mhpeditNetEnquiriecount"
            />
          </vs-col>
          <vs-col vs-w="8" vs-type="flex" vs-justify="center" vs-align="center">
            <v-select
              style="width: 420px; position: fixed; z-index: 900"
              v-model="mhpselectedColoumnSpoc"
              :options="iiml_spocs"
              label="full_name_team"
            ></v-select>
          </vs-col>
          <vs-col vs-w="2" vs-justify="center" vs-align="center">
            <vs-button
              color="success"
              @click="transferMHPNetEnquiries()"
              type="filled"
              >Assgin</vs-button
            >
          </vs-col> </vs-row
        ><br />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DateRangePicker from "vue2-daterange-picker";
// import eventbus from "../components/eventbus";
export default {
  mounted() {
    this.spoc_id = localStorage.getItem("spoc_id");
    console.log("hjgfghfughfui", this.spoc_id);
    this.getGmReportingSpocs(this.selectedTeam);
  },
  data() {
    return {
      spoc_id: "",
      from_spoc_name: "",
      iiml_spocs: [],
      selected_course: [],
      ne_bucket1_source: [],
      ne_bucket2_source: [],
      selected_ne_course: [],
      selected_ne1_source: [],
      selected_ne2_source: [],
      visit_scheduled: false,
      dateRange: {
        startDate: '',
        endDate: '',
      },
      ne_courses: [
        {
            id: "All",
            label: "All",
            children: [
              { id: "CPA", label: "CPA" },
              { id: "CMA", label: "CMA" },
              { id: "CFA", label: "CFA" },
              { id: "FRM", label: "FRM" },
              { id: "USP", label: "USP" },
            ]
        }
      ],
      ne1_sources: [
          {
            id: "B1",
            label: "B1",
            children: [
              { id: "Google", label: "Google" },
              { id: "Miles Website", label: "Miles Website" },
              { id: "LinkedIn", label: "LinkedIn" },
              { id: "USP website", label: "USP website" },
              { id: "Website", label: "Website" },
              { id: "SuperBot", label: "SuperBot" },
              { id: "WhatsApp", label: "WhatsApp" },
              { id: "YouTube", label: "YouTube" },
            ]
          },
      ],
      ne2_sources: [
          {
            id: "B2",
            label: "B2",
            children: [
              { id: "Webinar", label: "Webinar" },
              { id: "CA Data", label: "CA Data" },
              { id: "Placement Drive", label: "Placement Drive" },
              { id: "Meta", label: "Meta" },
              { id: "Naukri", label: "Naukri" },
              { id: "Li_inmails", label: "Li_inmails" },
              { id: "Email", label: "Email" },
              { id: "Socials", label: "Socials" },
              { id: "Business Partner", label: "Business Partner" },
              { id: "Voice SMS", label: "Voice SMS" },
              { id: "YouTube", label: "YouTube" },
              { id: "Live Chat", label: "Live Chat" },
              { id: "Chat Enquiries", label: "Chat Enquiries" },
              { id: "gupshup", label: "gupshup" },
              { id: "Organic", label: "Organic" },
              { id: "LMS", label: "LMS" },
            ]
          }
      ],
      team_select: this.team,
      colorx: "rgb(199, 42, 117)",
      editspoctitle: "",
      mhpeditspoctitle: "",
      netEnquiriesPopup: false,
      mhpnetEnquiriesPopup: false,
      mhpeditNetEnquiriecount: "",
      editNetEnquiriecount: "",
      selectedColoumnSpoc: "",
      mhpselectedColoumnSpoc: "",
      mhp_iiml_spocs: [],
      spocs: [],
      selected_spoc: "",
      selected_city: "",
      cityString: "",
      SpocId: "",
      SpocName: "",
      sortSpoc: [],
      spocsByCity: [],
      allSpocs: [],
      from_spoc: "",
      ne_selected_spoc_list: [],
      mhp_selected_spocid_list: [],
      mhp_selected_spoc_firstname_list: [],
      count: "",
      selectedTeam: "CM",
      cmTeamSelectButtonColor: [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      exedTeamSelectButtonColor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      roles: ["IIML-PM", "IIML-HR", "IITR-BF", "IIML-BA"],
      ne_bucket: '',
      mhp_type: '',
    };
  },
  components: {
    "v-select": vSelect,
    DateRangePicker,
    Treeselect
  },
  watch: {
    selected_course(val){
      console.log('selected_course' ,val);
    },
    ne_bucket1_source(val){
      console.log('ne_bucket1_source' ,val);
    },
    ne_bucket2_source(val){
      console.log('ne_bucket2_source' ,val);
    },
    selected_ne1_source(val){
      console.log('selected_ne1_source' ,val);
    },
    selected_ne2_source(val){
      console.log('selected_ne2_source' ,val);
    },
    selected_ne_course(val){
      console.log('selected_ne_course' ,val);
    },
  },
  methods: {
    cmTeam(team) {
      if (this.getTeam("GM")) {
        this.iiml_spocs = [];
        this.exedTeamSelectButtonColor = [
          {
            background: "#ffffff",
            color: "rgb(199, 42, 117)",
            border: "1px solid rgb(199, 42, 117)",
          },
        ];
        this.cmteamselectbuttoncolor = [
          {
            background: "rgb(199, 42, 117)",
            color: "#ffffff",
            border: "1px solid rgb(199, 42, 117)",
          },
        ];
        console.log(team, "team123213");
        // this.getGmReportingSpocs(team);
      }
    },
    exedTeam(team) {
      if (this.getTeam("GM")) {
        this.iiml_spocs = [];
        console.log(team, "team123213");
        this.cmTeamSelectButtonColor = [
          {
            background: "#ffffff",
            color: "rgb(199, 42, 117)",
            border: "1px solid rgb(199, 42, 117)",
          },
        ];
        this.exedTeamSelectButtonColor = [
          {
            background: "rgb(199, 42, 117)",
            color: "#ffffff",
            border: "1px solid rgb(199, 42, 117)",
          },
        ];
        // this.getGmReportingSpocs(team);
      }
    },
    getGmReportingSpocs(team) {
      let url = `${constants.SERVER_API}getGmReportingSpocs?team=${team}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGmReportingSpocs", response);
          this.iiml_spocs = [];
          response.data.spocs.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} - ${
              spoc.user_tag ? spoc.user_tag : " "
            } - ${spoc.city}`;
            if (
              spoc.assign_net_enquiry === 1 ||
              spoc.assign_net_enquiry === 0
            ) {
              this.iiml_spocs.push(spoc);
            }
          });

          // this.selected_spocs = response.data.spocs;
          // console.log("step1", this.selected_spocs);
          this.loggedin = response.data.logged_in_user_id;
          // response.data.spocs = this.removeUnwwantSpocs(response.data.spocs);
          this.SpocName = response.data.spocs;
          // this.extractSpocByCity();
          // this.getSpocIds(spoc_data);
          this.selectedTeam = team;
          this.assignLead(this.SpocName, this.selectedTeam, this.ne_bucket1_source, this.ne_bucket2_source, this.selected_course, this.visit_scheduled);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    editNe_1(selectedSpocData) {
      this.ne_bucket = 1;
      console.log("ryrtyrty", selectedSpocData);
      this.selectedColoumnSpoc = "";
      this.editNetEnquiriecount = "";
      this.selectedColoumnSpoc = [];
      this.selected_ne1_source = [];
      this.selected_ne2_source = [];
      this.selected_ne_course = [];
      this.from_spoc = selectedSpocData.id;
      console.log("selectedSpocData", selectedSpocData);
      this.editspoctitle = "NE Bucket 1" + " " + "-" + " " +
        selectedSpocData.first_name +
        ",  " +
        selectedSpocData.city +
        ",    Total : " +
        selectedSpocData.ne_bucket_1;
      //   this.editNetEnquiriecount = selectedSpocData.net_enquiries_sorted.length
      this.netEnquiriesPopup = true;
    },
    editNe_2(selectedSpocData) {
      this.ne_bucket = 2;
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.selectedColoumnSpoc = [];
      this.selected_ne1_source = [];
      this.selected_ne2_source = [];
      this.selected_ne_course = [];
      this.editNetEnquiriecount = "";
      this.from_spoc = selectedSpocData.id;
      console.log("selectedSpocData", selectedSpocData);
      this.editspoctitle = "NE Bucket 2" + " " + "-" + " " +
        selectedSpocData.first_name +
        ",  " +
        selectedSpocData.city +
        ",    Total : " +
        selectedSpocData.ne_bucket_2;
      //   this.editNetEnquiriecount = selectedSpocData.net_enquiries_sorted.length
      this.netEnquiriesPopup = true;
    },
    mhpCalledPopUp(mhpspocdata) {
      this.mhp_type = 'called'
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.mhpselectedColoumnSpoc = [];
      this.mhpnetEnquiriesPopup = true;
      this.mhpeditNetEnquiriecount = "";
      this.mhpselectedColoumnSpo = "";
       this.from_spoc = mhpspocdata.id;
       this.from_spoc_name = mhpspocdata.first_name; //full name to first name
       this.mhpeditspoctitle = "MHP Called" + " " + "-" + " " +
       mhpspocdata.first_name +
        ",  " +
        mhpspocdata.city +
        ",    Total : " +
        mhpspocdata.mhp_called;
      console.log("mhpCalledPopUp", mhpspocdata);
    },
    mhpNotCalledPopUp(mhpspocdata) {
      this.mhp_type = 'not_called'
      this.selected_spoc_user_tag = "";
      this.selected_spoc_city = "";
      this.mhpselectedColoumnSpoc = [];
      this.mhpnetEnquiriesPopup = true;
      this.mhpeditNetEnquiriecount = "";
      this.mhpselectedColoumnSpo = "";
       this.from_spoc = mhpspocdata.id;
       this.from_spoc_name = mhpspocdata.first_name; //full name to first name
       this.mhpeditspoctitle = "MHP Not Called" + " " + "-" + " " +
       mhpspocdata.first_name +
        ",  " +
        mhpspocdata.city +
        ",    Total : " +
        mhpspocdata.mhp_not_called;
      console.log("mhpNotCalledPopUp", mhpspocdata);
    },
    async assignLead(spoc, team, ne_bucket1_source, ne_bucket2_source, selected_course, visit_scheduled) {
      this.$vs.loading();
      if (spoc.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 2000);
      }
      console.log("spoc length not 0");
      for (let i = 0; i < spoc.length; i++) {
        console.log("spocs in for lop", spoc);
        let response = await this.getDashbaordStatsSpoc(spoc[i].id, team, ne_bucket1_source, ne_bucket2_source, selected_course, visit_scheduled);

        console.log("getDashbaordStatsSpoc response12345", response);
        // spoc[i].net_enquiries = response.data.net_enquiries;
        spoc[i].ne_bucket_1 = response.data.ne_bucket_1;
        spoc[i].ne_bucket_2 = response.data.ne_bucket_2;
        spoc[i].mhp_called = response.data.mhp_called;
        spoc[i].mhp_not_called = response.data.mhp_not_called;
        // spoc[i].net_enquiries_sorted = this.NetEnquiryaggregator(
        //   response.data.net_enquiries
        // );
      }
      this.$vs.loading.close();
      this.sortSpoc = spoc;
      console.log("sortspoc", this.sortSpoc);
    },
    getDate(date) {
      var datum = Date.parse(date);
      return datum/1000;
    },
    getDashbaordStatsSpoc(spoccount, team, ne_bucket1_source, ne_bucket2_source, selected_course, visit_scheduled) {
      // let SpocId = spoccount;
      let end_date = '';
      let start_date = '';
      if (
        this.getDate(this.dateRange.startDate) != "Invalid date" && this.dateRange.startDate != '' && this.dateRange.endDate != '' &&
        this.getDate(this.dateRange.endDate) != "Invalid date"
      ) {
        start_date = this.getDate(this.dateRange.startDate);
        end_date = this.getDate(this.dateRange.endDate);
      }
      let url = `${constants.SERVER_API}getNEForAssignment?spoc_id=${spoccount}&team=${team}&selected_source_b1=${ne_bucket1_source}&selected_source_b2=${ne_bucket2_source}&ne_courses=${selected_course}&start_date=${start_date}&end_date=${end_date}&visit_scheduled=${visit_scheduled}`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
      return promise;
    },
    sortSpocs(value) {
      console.log("value", value);
      this.spocsByCity = [];
      this.allSpocs = [];
      console.log(this.SpocName);
      this.SpocName.forEach((spoc) => {
        // console.log("fsafd", spoc);
        this.allSpocs.push(spoc);
        if (spoc.city === value) {
          this.spocsByCity.push(spoc);
        } else if (spoc.sub_team === value) {
          this.spocsByCity.push(spoc);
        }
      });
      //   this.assignLead(sort);
      console.log("sort", this.spocsByCity);
      this.assignLead(this.spocsByCity, this.selectedTeam, this.ne_bucket1_source, this.ne_bucket2_source, this.selected_course, this.visit_scheduled);
    },
    transferNetEnquiries() {
      this.$vs.loading();
      let obj = {};
      console.log("step2", this.selectedColoumnSpoc);
      let selected_spoc = [this.selectedColoumnSpoc.id];
      let end_date = '';
      let start_date = '';
      if (
        this.getDate(this.dateRange.startDate) != "Invalid date" && this.dateRange.startDate != '' && this.dateRange.endDate != '' &&
        this.getDate(this.dateRange.endDate) != "Invalid date"
      ) {
        start_date = this.getDate(this.dateRange.startDate);
        end_date = this.getDate(this.dateRange.endDate);
      }
      obj = {
        from_spoc: this.from_spoc,
        to_spoc: selected_spoc,
        count: this.editNetEnquiriecount,
        team: this.selectedTeam,
        ne_bucket: this.ne_bucket,
        ne1_source: "",
        ne2_source: "",
        ne_courses: this.selected_ne_course.join(),
        start_date: start_date,
        end_date: end_date,
        visit_scheduled: this.visit_scheduled
      };
      if(this.selected_ne1_source.length != 0){
        obj.ne1_source = this.selected_ne1_source.join();
      }else if(this.selected_ne2_source.length != 0){
        obj.ne2_source = this.selected_ne2_source.join();
      }
      console.log("net enquiree transfer", obj);
      if (obj.count !== "") {
        let url = `${constants.SERVER_API}reassignNetEnquiry`;
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            this.$vs.loading.close();
            console.log(response);
            this.handleNotification(response);
            this.netEnquiriesPopup = false;
            // this.sortSpocs(this.selected_city);
            if (this.getTeam("GM") || this.getTeam("Admin") ) {
              this.getGmReportingSpocs("CM");
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        setTimeout(() => {
        this.$vs.loading.close();
        }, 500);
        this.$vs.notify({
          title: "Require Count",
          text: "Please give count of net enquiries you transfer",
          color: "danger",
        });
      }
    },
    transferMHPNetEnquiries() {
      let obj = {};
      this.mhp_selected_spoc_firstname_list = [];
      this.mhp_selected_spocid_list = [];
      console.log("step2", this.mhpselectedColoumnSpoc);
      obj = {
        mhp_type: this.mhp_type,
        from_spoc: this.from_spoc,
        from_spoc_name: this.from_spoc_name,
        to_spoc: this.mhpselectedColoumnSpoc.id,
        to_spoc_name: this.mhpselectedColoumnSpoc.first_name,
        count: this.mhpeditNetEnquiriecount,
        team: this.selectedTeam,
      };

      console.log("MHP net enquiree transfer", obj);
      if (obj.count !== "") {
        let url = `${constants.SERVER_API}reassignNEMhps`;
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            this.handleNotification(response);
            this.mhpnetEnquiriesPopup = false;
            // this.sortSpocs(this.selected_city);
            if (this.getTeam("GM")) {
              this.getGmReportingSpocs("CM");
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        this.$vs.notify({
          title: "Require Count",
          text: "Please give count of net enquiries you transfer",
          color: "danger",
        });
      }
    },
    refreshFilterData(){
      this.dateRange ={
        startDate: '',
        endDate: '',
      }
      this.selected_course = []
      this.ne_bucket1_source = []
      this.ne_bucket2_source = []
      this.visit_scheduled = false
      this.getGmReportingSpocs(this.selectedTeam);
    }
  },
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
  font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
  font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
  font-size: 13px;
}
.con-vs-popup .vs-popup {
  width: 400px;
}
.assignspocpopupmultiplewidthnet .vs-popup {
  width: 40% !important;
  /* height: 30% !important; */
}
.editTxt {
  font-size: 135%;
}
.gap {
  margin-right: 1%;
}
</style>
